import App from 'next/app';
import React from 'react';
import { Provider } from 'react-redux';
import IntlProviderWrapper from '@/utils/IntlProviderWrapper';
import withDva from '@/utils/withDva';
import models from '@/models';
import viData from '@/locales/vi';
import cookiee from 'cookie';
import { languages } from '@/utils/utils';

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    ctx.roles = [];
    let pageProps = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps({ ...ctx });
    }
    let cookies = {};

    // Kiểm tra nếu chúng ta đang ở phía server
    if (ctx.req) {
      // Lấy cookies từ headers
      cookies = cookiee.parse(ctx.req.headers.cookie || '');
    } else {
      const documentCookie = document.cookie;
      cookies = cookiee.parse(documentCookie);
    }
    const getMessages = locale => {
      // eslint-disable-next-line import/no-dynamic-require
      const messages = require(`../locales/${locale}`);
      // console.log("messages: ", messages)
      return messages;
    };

    const locale =
      (ctx?.query?.lang &&
        languages?.find(i => i?.languagesCode === ctx?.query?.lang)?.languagesCode) ||
      cookies?.locale ||
      'vi';
    const initialNow = Date.now();
    return { pageProps, initialNow, locale, messages: getMessages(locale) };
  }

  render() {
    const { Component, pageProps, locale, messages, initialNow, store } = this.props;
    return (
      <Provider store={store}>
        <IntlProviderWrapper
          locale={locale || 'vi'}
          messages={messages || viData}
          initialNow={initialNow}
        >
          {/* <TopProgressBar /> */}
          <Component {...pageProps} />
        </IntlProviderWrapper>
      </Provider>
    );
  }
}

export default withDva(models)(MyApp);
